.card-list {
  @include outer-container;
  padding: space(1) space(1) space(2);
}

// MEDIUM
@include mq(medium) {
  .card-list {
    padding: 0;

    .card {
      width: calc(25% - #{space(2)});
      margin: 0 space(1) space(2);
      flex-grow: 0;
    }

    .card--large {
      width: calc(50% - #{space(2)});
      margin: 0 space(1) space(2);
    }
  }

  .card-list__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

  }
}
