/* stylelint-disable declaration-colon-space-after */
// Disable Bourbon Prefixes
$prefix-for-webkit:    false;
$prefix-for-mozilla:   false;
$prefix-for-microsoft: false;
$prefix-for-opera:     false;
$prefix-for-spec:      false;

// This is the the base for the em/rem mixins and the grid calculation.
// If your $body__font-size is 100%, leave it at 16px.
$em-base: 16px;

// GRID
// ----------------------------------------
// Since the typical default browser font-size is 16px,
// that makes the calculation for grid size.
//
// THIS VARIABLES ARE FOR NEAT SETTING! DON'T RENAME!
$gutter:              em(20);
$grid-columns:        12;
$max-width:           rem(1200);
$border-box-sizing:   true;

// Debug Grid
$visual-grid:         false;
$visual-grid-color:   #f00;
$visual-grid-index:   back;
$visual-grid-opacity: 0.1;

// MEDIAQUERIES
// ----------------------------------------
// 640px, 800px, 1200px
$breakpoints: (
  medium:   40em,
  large:    50em,
  xlarge:   75em
);

// COLORS
// --------------------------

// DON'T USE THIS NAME SPECIFIC VARIABLES IN OTHER FILES
$color__black:              #1e1e1e;
$color__white:              #faf4ec;

$color__grey:               #c8c8c3;
$color__grey--light:        #ebe6df;
$color__grey--medium:       #aaa;
$color__grey--dark:         #505050;

$color__red:                #f01;
$color__green:              #43ac6a;

$color__brand--one:         $color__red;
$color__brand--two:         $color__white;

// COLOR NAMING
// USE THIS VARIABLES IN OTHER FILES
$color__primary:            $color__brand--one;
$color__secondary:          $color__brand--two;
$color__tertiary:           $color__black;

$color__dark:               $color__black;
$color__bright:             $color__white;

$color__accent:             $color__grey;
$color__accent--light:      $color__grey--light;
$color__accent--medium:     $color__grey--medium;
$color__accent--dark:       $color__grey--dark;

$color__alert:              $color__red;
$color__success:            $color__green;

// SPACES
// ----------------------------------------
$space__default-value:        10;  // e.g. used for paddings

// TYPOGRAPHY
// ----------------------------------------
$font-weight--light:          300;  // futura book
$font-weight--regular:        400;  // garamond roman, Berranger
$font-weight--medium:         500;  // futura medium
$font-weight--bold:           700;  // futura bold, garamond bold
$font-weight--heavy:          800;  // futura heavy

$font-family--sans:           'Futura W01', sans-serif;
$font-family--serif:          'Classical Garamond W01', serif;
$font-family--quote:          'MarketWeb W01 Regular', serif;

$body__font-family:           $font-family--sans;
$body__font-size:             100%;
$body__font-weight:           $font-weight--light;
$body__font-style:            normal;
$body__font-color:            $color__dark;
$body__line-height:           1.2;
$body__background-color:      $color__bright;

// HEADINGS font styles
$heading__font-family:        $font-family--serif;
$heading__font-weight:        $font-weight--bold;
$heading__font-style:         normal;
$heading__font-color:         $body__font-color;
$heading__text-rendering:     auto;

// PARAGRAPHS
$paragraph__font-family:      inherit;
$paragraph__font-weight:      $font-weight--light;
$paragraph__font-size:        rem(14);
$paragraph__line-height:      1.43;
$paragraph__margin-bottom:    rem(24);
$paragraph__text-rendering:   auto;

// ANCHORS
$anchor__text-decoration:     none;
$anchor__font-color:          $color__primary;
$anchor__font-color-hover:    $color__accent--dark;
$anchor__font-color-active:   $color__tertiary;

// <HR>
$hr__margin-top:              rem(18);
$hr__margin-bottom:           $hr__margin-top;
$hr__border-width:            rem(1);
$hr__border-style:            solid;
$hr__border-color:            $color__accent;

// LISTS
$list__style-position:        outside;
$list__side-margin:           0;
$list__nested-margin:         0;
$list__side-padding:          rem(18);
$list__ol-side-padding:       rem(23);
$list__font-size:             $paragraph__font-size;

// BLOCKQUOTE
$blockquote__font-family:     $font-family--quote;
$blockquote__font-size:       rem(21);
$blockquote__font-style:      normal;
$blockquote__font-weight:     $font-weight--regular;
$blockquote__margin:          0 0 $paragraph__margin-bottom 0;

// RADIUS
$border-radius:               0;
$border-radius--round:        100%;

/* stylelint-enable declaration-colon-space-after */
