$language-selector__arrow-color--large: str-replace(inspect($color__accent--medium), '#', '%23');

.language-selector {
  width: auto;
  position: relative;
}

.language-selector__current {
  display: none;
}

.language-selector__links {
  display: block;
  margin-top: rem(20);

  a {
    padding: 0 rem(20);
    line-height: 2.5;

    &:first-child {
      padding-left: 0;
    }
  }
}

@include mq(large) {
  .language-selector__links {
    display: none;
    position: absolute;
    right: 0;
    padding: rem(10);
    background-color: $color__dark;
    margin-top: -5px;
    z-index: 2;

    a {
      color: $color__bright;

      &:hover {
        color: $color__accent--medium;
      }

      &:first-child {
        padding-left: rem(20);
      }
    }

    .language-selector:hover & {
      display: block;
    }
  }

  .language-selector__current {
    font-weight: $font-weight--bold;
    display: block;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M4.97%207.97l6.61%208.06%207.45-8.06h-14.06z%22%20fill%3D%22#{$language-selector__arrow-color--large}%22%2F%3E%3C%2Fsvg%3E');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center right;
    color: currentColor;
    padding-left: rem(4);
    padding-right: rem(30);
    width: auto;
    cursor: pointer;
    font-size: rem(15);
    line-height: 3.13;
    margin-bottom: 0;

    .language-selector:hover & {
      color: $color__dark;
    }
  }

  // VARIANTS
  .header--with-img {
    //stylelint-disable selector-max-specificity
    .language-selector:hover .language-selector__current {
      color: $color__bright;
    }
    //stylelint-enable selector-max-specificity
  }
}
