/* stylelint-disable selector-class-pattern */
.iframe_component {
  margin-left: auto;
  margin-right: auto;
  max-width: 790px;
}

.iframe_container {
  position: relative;
  height: 0;
  overflow: hidden;
}

.iframe_ratio16to9 {
  padding-bottom: 56.25%;
}

.iframe_ratio4to3 {
  padding-bottom: 75%;
}

.iframe_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* stylelint-enable selector-class-pattern */
