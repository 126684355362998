$topbar__border: rem(1) solid $color__accent;

.topbar {
  width: 100%;
}

.topbar__menu-btn {
  color: $color__accent--dark;
  display: block;
  position: absolute;
  top: rem(10);
  right: 0;
  padding: 0 rem(12);
  text-align: right;
  z-index: 101;

  button {
    background-color: transparent;
    border: 0;
    color: currentColor;
    outline: none;
    padding: rem(7) 0 rem(6) rem(6);
    position: relative;
    text-transform: uppercase;
    font-size: rem(12);
    cursor: pointer;

    .icon {
      position: relative;
      top: -0.2em;
    }
  }

  .topbar__menu-close {
    display: none;
  }
}

.topbar__inner {
  display: none;
}

.topbar__list,
.topbar__sub-list {
  list-style: none;
  margin: 0;
  padding: rem(20);
}

.topbar__list {
  display: block;
}

.topbar__item {
  display: block;

  a {
    display: block;
    font-size: rem(18);
  }

  > a {
    color: $color__accent;
    font-weight: $font-weight--bold;
    letter-spacing: rem(0.5);
    padding: rem(12) 0;
  }
}

.topbar__sub-list {
  display: none;
  padding: 0;
}

.topbar__sub-item {
  display: block;

  a {
    border-color: $color__bright;
    color: $color__bright;
    padding: rem(5) rem(5) rem(5) rem(25);
    font-weight: $font-weight--regular;
  }
}

// VARIANTS
.header--with-img {
  .topbar__menu-btn {
    color: $color__bright;
  }
}
// STATES
.topbar__item,
.topbar__sub-item {
  > a:hover {
    color: $color__accent--medium;
  }

  &:hover {
    transition: all 0.1s linear;
  }
}

.topbar__item--open {
  .topbar__sub-list {
    display: block;
  }
}

.topbar--open {
  &,
  body {
    overflow: hidden;
  }

  .topbar__menu-close {
    display: block;
  }

  .topbar__menu-open {
    display: none;
  }

  .topbar__inner {
    display: block;
    position: absolute;
    background-color: $color__dark;
    top: rem(60);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.topbar__sub-list--open {
  display: block;
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .topbar {
    font-size: rem(18);
  }

  .topbar__menu-btn {
    display: none;
  }

  .topbar__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .topbar__list,
  .topbar__sub-list {
    padding: 0;
  }

  .topbar__list {
    flex: 1 0 auto;

    &,
    .topbar--open & {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      position: relative;
      width: auto;
      height: auto;
      background-color: transparent;
      border-top: 0;
      overflow: visible;
    }
  }

  .topbar__item {
    flex: 0 1 auto;
    position: relative;

    > a {
      padding: 0 rem(7);
      line-height: 2.5;
      text-align: center;
      color: $color__accent--medium;
    }

    &:last-child {
      border-right: 0;
    }
  }

  .topbar__sub-list {
    display: block;
    background-color: $color__accent;
    left: rem(-20);
    position: absolute;
    z-index: 10;
    transform: translateY(-205%);
    transition: transform 0.3s ease-out;
    padding: rem(20) rem(25);

    .topbar__item--open & {
      transform: translateY(0);
    }
  }

  .topbar__sub-item {
    margin-bottom: rem(5);

    a {
      display: inline-block;
      background-color: transparent;
      padding: 0;
      font-size: rem(18);

      &:hover {
        color: $color__brand--one;
      }
    }
  }

  // STATES
  .topbar--open {
    &,
    body {
      overflow: auto;
    }

    .topbar__inner {
      position: relative;
      background-color: transparent;
      display: flex;
      top: auto;
      height: auto;
      z-index: auto;
      overflow: visible;
    }
  }

  .topbar__item--open > a {
    color: $color__dark;

    .header--with-img & {
      color: $color__bright;
    }
  }

  .topbar__item > a:hover {
    color: $color__dark;

    .header--with-img & {
      color: $color__bright;
    }
  }
}
