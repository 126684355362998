.content {
  @include outer-container;
  padding: 0 space(1);

  a {
    text-decoration: underline;
  }
}

.content--lead {
  font-family: $font-family--serif;
  font-size: rem(20);
  font-weight: $font-weight--bold;
  line-height: 1.2;
  margin: rem(9) 0 rem(18);
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  .content {
    padding: 0;
  }

  .content__inner {
    @include span-columns(10);
    @include shift(1);
  }

  // Variant with Image
  .content--with-image {
    .content__inner {
      @include span-columns(10);
      @include shift(1);
    }
  }

  .content__column-text,
  .content__column-image {
    @include span-columns(6);
  }
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .content__inner {
    @include span-columns(8);
    @include shift(2);
  }

  .content--lead {
    font-size: rem(30);
    margin: rem(44) 0 rem(33);
  }
}
