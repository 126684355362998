.intro {
  @include outer-container();
  padding: 0 space(1);
  position: relative;
}

.intro__text {
  h1 {
    margin-top: 0;
  }
}

// VARIANTS
.intro--only-text {
  text-align: center;
}

.intro--image-right {
  display: flex;
  flex-direction: column;

  .intro__image-wrapper {
    order: 1;
  }

  .intro__text {
    order: 2;
  }
}

@include mq(medium) {
  .intro__image-wrapper,
  .intro__text {
    @include span-columns(10);
    @include shift(1);
  }
}

@include mq(large) {
  .intro {
    padding: rem(110) space(1) rem(45);
  }

  .intro__text {
    @include shift(6);
    @include span-columns(6);
  }

  .intro__image-wrapper {
    @include shift(0);
    @include span-columns(6);
    position: absolute;

    img {
      display: block;
      transform: translateY(rem(-450));
    }
  }

  // VARIANTS
  .intro--only-text {
    .intro__text {
      @include span-columns(8);
      @include shift(2);
    }
  }

  .intro--image-right {
    display: block;

    .intro__text {
      @include shift(0);
      @include span-columns(6);
    }

    .intro__image-wrapper {
      @include shift(6);
      @include span-columns(6);
    }
  }
}
