.breadcrumb {
  display: none;
}

@include mq(medium) {
  .breadcrumb {
    @include clearfix;
    display: block;
    position: relative;
    padding: 0;
    margin: 0 0 rem(12);
    text-align: center;
    transform: translateY(-50%);

    &::before {
      background: $hr__border-color;
      content: '';
      display: block;
      height: $hr__border-width;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }
  }

  .breadcrumb__list {
    display: inline-block;
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0 rem(45);
    background-color: $color__accent;
    font-weight: $font-weight--light;
    font-size: rem(14);
    text-transform: uppercase;
    letter-spacing: rem(1);
  }

  .breadcrumb__item {
    display: inline-block;
    padding: rem(7) 0;

    &::after {
      content: '|';
      margin: 0 rem(5);
      color: $color__bright;
    }

    &:last-child {
      &::after {
        content: '';
        display: none;
      }
    }

    a {
      display: inline-block;
      color: $color__bright;

      &:hover {
        color: $color__primary;
      }

      &:active {
        color: $color__dark;
      }
    }
  }

  // STATES
  .breadcrumb__item--active {
    color: $color__accent--dark;
  }

}
