.embeds {
  padding: 0 space(1);
  margin-bottom: space(2);
  max-height: 400px;

  video {
    width: 100%;
    height: auto;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.embeds__inner {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: rem(25);
  height: 0;
}

// STATES
.embeds--widescreen {
  .embeds__inner {
    padding-bottom: 56.34%;
    padding-top: 0;
  }
}

@include mq(large) {
  .embeds--widescreen .embeds__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;

    iframe {
      max-width: 640px;
      max-height: 360px;
    }
  }
}
