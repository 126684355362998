.social-bar {
  display: inline-block;
  width: auto;
  background-color: $color__primary;
  padding: 0 rem(15);
}

.social-bar__btn {
  margin-right: 0;
  margin-bottom: 0;

  .icon {
    width: rem(25);
    height: rem(25);
  }
}
