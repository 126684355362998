.card {
  background-color: $color__accent--light;
  margin: 0 0 space(1);

  // MEDIUM
  @include mq(medium) {
    display: flex;
    flex: 0 1 auto;
  }

  @include mq(large) {
    margin-bottom: space(2);
  }
}

.card__link {
  display: block;
  width: 100%;
  padding: space(1);
  color: currentColor;
}

.card__img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  margin: 0 auto rem(10);

  @include mq(large) {
    margin-bottom: rem(16);
  }

  @include mq(large) {
    margin-bottom: rem(20);
  }
}

.card__title {
  width: 100%;
  margin: 0 auto rem(10);
  text-align: center;
  padding: 0 space(1);

  &::before {
    @include headline-line();
  }

  @include mq(large) {
    margin-bottom: space(2.5);
  }
}

// STATES
.card:hover {
  .card__link {
    color: currentColor;
  }

  .card__img {
    opacity: 0.8;
  }
}
