.topbar-meta {
  flex: 0 0 auto;
  text-align: left;
  list-style-type: none;
  margin: 0 0 0 auto;
  padding: 0 rem(20) rem(20);
}

.topbar-meta__item {
  display: block;
  font-size: rem(16);
  font-weight: $font-weight--bold;
  color: $color__accent;

  a {
    display: inline-block;
    color: currentColor;
    padding: rem(5) 0;

    &:hover {
      color: $color__accent--medium;
    }
  }
}

@include mq(large) {
  .topbar-meta {
    text-align: right;
    padding: 0;
  }

  .topbar-meta__item {
    display: inline-block;
    font-size: rem(18);
    color: $color__accent--medium;

    a {
      padding: 0 rem(4);
      font-size: rem(15);
      line-height: 3.13;
      color: currentColor;

      &:hover {
        color: $color__dark;
      }
    }
  }

  // VARIANTS
  .header--with-img {
    .topbar-meta__item {
      > a:hover {
        color: $color__bright;
      }
    }
  }
}
