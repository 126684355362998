$form__select-arrow-color: $color__accent--dark;

fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 rem(18);
}

legend {
  font-weight: $font-weight--bold;
  color: $color__primary;
  margin-bottom: rem(6);
}

label {
  display: inline-block;
  max-width: 100%;
  cursor: pointer;
  color: $color__accent--medium;
  font-size: rem(16);
  line-height: 1.5;
  padding: 0 rem(2);
}

input::placeholder {
  color: $color__accent--medium;
}

#{$all-text-inputs},
textarea {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $color__accent--medium;
  border-radius: 0;
  padding: rem(5) rem(2);
  font-size: rem(16);
  line-height: 1.5;
  margin-bottom: rem(10);

  &:focus {
    outline: 0;
    border-bottom-color: $color__dark;
  }
}

input[type='search'] {
  box-sizing: border-box;
}

// Define colors for select arrow and convert it to a URL encoded value.
$form__select-arrow-color: str-replace(inspect($color__bright), '#', '%23');
$form__select-arrow-color--focus: str-replace(inspect($color__dark), '#', '%23');

select {
  appearance: none;
  width: 100%;
  height: rem(44);
  padding: rem(9) rem(30) rem(9) rem(12);
  background-color: $color__accent;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M4.97%207.97l6.61%208.06%207.45-8.06h-14.06z%22%20fill%3D%22#{$form__select-arrow-color}%22%2F%3E%3C%2Fsvg%3E');
  background-position: right rem(5) center;
  background-repeat: no-repeat;
  border: rem(1) solid $color__accent;
  border-radius: 0;
  color: $color__bright;
  font-size: rem(16);
  line-height: 1.5;

  option {
    color: $body__font-color;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    color: $color__dark;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    color: $color__dark;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M4.97%207.97l6.61%208.06%207.45-8.06h-14.06z%22%20fill%3D%22#{$form__select-arrow-color--focus}%22%2F%3E%3C%2Fsvg%3E');

    &::-ms-value {
      color: $color__primary;
      background-color: transparent;
    }
  }
}

// INPUT WITH BUTTON ON THE SIDE
.input-addon {
  display: flex;
}

.input-addon__field {
  flex: 1;
}

.input-addon__item {
  margin-bottom: 0;
}

// INPUT WITH ICON BUTTON INSIDE
.input-overlay {
  position: relative;

  .input-overlay__input {
    padding-right: rem(50);
  }
}

.input-overlay__button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  color: $body__font-color;

  &,
  &:hover,
  &:active {
    background-color: transparent;
  }

  &:hover {
    color: $color__grey--dark;
  }
}

// STATES
.form--field-error {
  label {
    color: $color__alert;
  }

  input {
    border-color: $color__alert;
  }
}

.form--field-valid {
  label {
    color: $color__success;
  }

  input {
    border-color: $color__success;
  }
}

// MEDIUM
// ----------------------------------------
@include mq(medium) {
  // STATES
  .form--horizontal {
    @include outer-container;
    margin-bottom: rem(18);

    label {
      @include span-columns(3);
      padding-top: rem(9);
    }

    input,
    select {
      @include span-columns(9);
    }
  }
}
