@font-face {
  font-family: 'Classical Garamond W01';
  src: url('../fonts/766138e8-7a16-4b76-84d1-1f172325bb83.woff2') format('woff2'), url('../fonts/f86c90ab-c6a6-41d8-b869-123a4c45e57a.woff') format('woff'), url('../fonts/a5972d85-7da6-4650-9aac-19936b2c450f.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Classical Garamond W01';
  src: url('../fonts/152e5576-93bc-4411-973d-cd1f8fad6284.woff2') format('woff2'), url('../fonts/ea6c4f29-0d14-4230-aa3c-4ff004346a39.woff') format('woff'), url('../fonts/3915d0cc-b5de-4485-90cf-404c68ea780b.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Classical Garamond W01';
  src: url('../fonts/bc9c78a2-82a6-4204-aa68-1eb7bbbf6a06.woff2') format('woff2'), url('../fonts/e62d6de7-8027-47b6-b71e-522b551bb235.woff') format('woff'), url('../fonts/283e8c99-0e4e-4d35-bc04-4189b52e1141.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Futura W01';
  src: url('../fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2') format('woff2'), url('../fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff') format('woff'), url('../fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura W01';
  src: url('../fonts/e17c7943-8db2-4470-9e2a-74a8d01f6776.woff2') format('woff2'), url('../fonts/6c72b0cb-89a1-4da9-8574-9244967bd9d6.woff') format('woff'), url('../fonts/dd79c75a-b0c9-4d70-a5a7-bdde1d5449fd.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura W01';
  src: url('../fonts/f3fa9288-dc04-4f75-b949-b06584863c9c.woff2') format('woff2'), url('../fonts/6b1bbd38-7556-4809-a151-88182a37116e.woff') format('woff'), url('../fonts/1f56887e-52d4-4229-b219-513cbef3ff58.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura W01';
  src: url('../fonts/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2') format('woff2'), url('../fonts/184895ac-545b-450b-9d3a-c0a0a7620977.woff') format('woff'), url('../fonts/bf8628d2-1ae0-4645-ba3f-3ce62d8d907c.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MarketWeb W01 Regular';
  src: url('../fonts/1b145514-5cda-45d8-bae4-20ed9257c815.woff2') format('woff2'), url('../fonts/03871a11-61d0-444f-aa7b-90a92217d999.woff') format('woff'), url('../fonts/ee840d1a-b1e1-4f80-b3a3-1058709a2c62.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
