.quote {
  background-color: $color__dark;
  padding: rem(85) rem(20) rem(50);
  color: $color__bright;

  blockquote {
    margin-bottom: rem(50);
  }

  footer {
    text-transform: uppercase;
    font-size: rem(12);
    line-height: 1.33;
    letter-spacing: rem(1);
    padding: 0 rem(45);
    margin: 0 rem(30);
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: rem(6);
      width: rem(25);
      height: rem(2);
      background-color: $color__primary;
    }
  }

  cite {
    font-style: normal;
  }
}

@include mq(medium) {
  .quote {
    padding: rem(175) 0 rem(100);

    blockquote {
      @include span-columns(8);
      @include shift(2);
      @include omega();
      margin-bottom: rem(120);
    }

    footer {
      @include span-columns(8);
      @include shift(2);
      @include omega();
      font-size: rem(14);
      line-height: 1.42;

      &::before {
        top: rem(8);
        width: rem(30);
      }
    }
  }

  .quote__inner {
    @include outer-container();
  }
}
