/* stylelint-disable selector-class-pattern */
.team-member {
  max-width: rem(400);
  text-align: center;
  margin-bottom: rem(80);
}

.team-member__img {
  padding-bottom: rem(8);
  border-bottom: 1px solid $hr__border-color;
  margin-bottom: rem(10);
}

.team-member__name {
  font-family: $font-family--serif;
  font-style: italic;
  margin-top: 0;
  margin-bottom: rem(15);

  &::after {
    @include headline-line();
  }
}

.team-member__function,
.team-member__phone,
.team-member__companyName,
.team-member__companyFunction,
.team-member__mail {
  display: block;
  color: $color__accent--medium;
  letter-spacing: rem(1);
  text-transform: uppercase;
  font-size: rem(14);
}

.team-member__function {
  margin-top: 0;
  margin-bottom: rem(25);
}

.team-member__companyFunction {
  margin-top: rem(25);
  margin-bottom: 0;
}

.team-member__companyName {
  margin-bottom: rem(25);
  margin-top: 0;
}

.team-member__phone,
.team-member__mail {
  line-height: 1.6;

  &:hover {
    color: $color__primary;
  }

  &:active {
    color: $color__dark;
  }
}
/* stylelint-enable selector-class-pattern */
