.tile {
  position: relative;
  min-height: rem(300);
  padding: rem(40) rem(20) 0;
  color: $color__bright;
  background-color: $color__accent--medium;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;

  @include mq(medium) {
    padding: rem(40) rem(40) 0;
  }

  @include mq(large) {
    min-height: rem(400);
    padding: rem(90) rem(110) 0;
  }
}

.tile__category {
  color: $color__accent--medium;
  text-transform: uppercase;
}

.tile__title {
  color: currentColor;
  margin: 0;
  font-weight: $font-weight--bold;
}

.tile__subtitle {
  color: currentColor;
  margin: 0 0 rem(15);
  font-weight: $font-weight--regular;
}

// Variant NO IMAGE
.tile--no-image {
  color: $color__dark;
  background-color: transparent;
}
