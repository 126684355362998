img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

.image {
  img {
    margin-bottom: rem(12);
  }
}

.image__caption {
  padding: 0 space(1);
  margin-bottom: rem(12);
  font-size: rem(12);
  font-weight: $font-weight--light;
  line-height: 1.5;
  color: $color__accent--dark;
}

// LARGE
// ----------------------------------------
@include mq(large) {
  .image {
    img {
      margin-bottom: rem(24);
    }
  }

  .image__caption {
    font-size: rem(16);
    line-height: 1.5;
  }
}
