$btn__size: rem(23);

// Default Button
.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0 0 rem(18);
  padding: rem(8) rem(25);
  background-color: $color__primary;
  border: rem(1) solid $color__primary;
  text-decoration: none;
  text-align: center;
  font-size: rem(14);
  font-weight: $font-weight--heavy;
  line-height: 1.33;
  color: $color__bright;
  user-select: none;
  appearance: none;
  transition: all 0.1s ease-in-out;

  .icon {
    width: $btn__size;
    height: $btn__size;
    vertical-align: top;
  }

  &:hover {
    color: $color__bright;
    background: $color__grey--dark;
    border-color: $color__grey--dark;
  }

  &:active,
  &.btn--active {
    transition: none;
    color: $color__bright;
    background: $color__dark;
    border-color: $color__dark;
  }

  &:focus {
    outline: 0;
  }
}

// Secondary Button
.btn--secondary {
  color: $color__primary;
  border-color: $color__bright;

  &,
  &:hover,
  &:active,
  .btn--active {
    background-color: $color__accent--light;
  }

  &:hover {
    color: $color__grey--dark;
    border-color: $color__grey--dark;
  }

  &:active,
  &.btn--active {
    color: $color__dark;
    border-color: $color__dark;
  }
}

// Tertiary Button
.btn--tertiary {
  color: $color__primary;
  text-decoration: underline;

  &,
  &:hover,
  &:active,
  &.btn--active {
    border-color: transparent;
    background-color: transparent;
  }

  &:hover {
    color: $color__accent--dark;
  }

  &:active,
  &.btn--active {
    color: $color__dark;
  }
}

// Only Icon Style
.btn--icon {
  width: rem(40);
  height: rem(40);
  padding: rem(8) 0;
  border: 0;
  color: $color__bright;

  &:hover,
  &:active {
    background-color: $color__primary;
  }

  &:hover {
    color: $color__accent;
  }

  &:active {
    color: $color__dark;
  }
}

// Expanded Style
.btn--expanded {
  width: 100%;
}

.button-static-top,
.button-fixed-bottom {
  z-index: 1;
  margin: 0;
}

.button-static-top {
  position: absolute;
  top: rem(60);
  right: rem(16);

  @media (min-width: 1220px) {
    // Calculate the position from right with max-width + additional right indention.
    right: calc(((100% - 75rem) / 2) + 1rem);
  }
}

.button-fixed-bottom {
  position: fixed;
  right: rem(35);
  bottom: rem(34);

  @media (min-width: 1220px) {
    // Calculate the position from right with max-width + additional right indention.
    right: calc(((100% - 75rem) / 2) + 2.1875rem);
  }

  &.btn {
    background-color: rgba($color__brand--one, 0.75);
    border-color: rgba($color__brand--one, 0.75);

    &:hover {
      background: rgba($color__grey--dark, 0.75);
      border-color: rgba($color__grey--dark, 0.75);
    }
  }
}

// LARGE
@include mq(large) {
  .btn {
    font-size: rem(15);
    padding-left: rem(35);
    padding-right: rem(35);
  }

  .btn--icon {
    padding: rem(8) 0;
  }

  .button-static-top {
    top: rem(94);
  }
}
