.team-filter {
  @include clearfix;
  display: block;
  position: relative;
  padding: 0;
  margin: rem(20) 0 rem(40);
  text-align: center;
  transform: translateY(-50%);

  &::before {
    background: $hr__border-color;
    content: '';
    display: block;
    height: $hr__border-width;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  li {
    display: block;
    position: relative;
    background-color: $color__bright;
    padding: rem(5) rem(10);
  }
}

.team-filter__link {
  display: inline-block;
  color: $color__accent--medium;

  &:hover {
    color: $color__dark;
  }
}

.team-filter--active {
  color: $color__dark;
  border-bottom: 1px solid $color__primary;
}
