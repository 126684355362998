.subtitle {
  padding: 0 space(1);

  h2 {
    position: relative;
    text-align: center;

    &::before {
      background: $hr__border-color;
      content: '';
      display: block;
      height: $hr__border-width;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }
  }

  span {
    display: inline-block;
    position: relative;
    max-width: 80%;
    padding: 0 rem(25);
    background: $body__background-color;
    z-index: 1;
  }
}

//LARGE
@include mq(large) {
  .subtitle {
    span {
      max-width: $max-width;
      padding: 0 rem(30);
    }
  }

}
