.footer-meta {
  @include outer-container(rem(1440));
  padding: 0 space(1);
  margin-bottom: rem(20);
  text-align: center;

  a {
    display: inline-block;
    padding: 0 rem(5);
    color: $color__accent--medium;

    &:hover {
      color: $anchor__font-color;
    }

    &:active {
      color: $anchor__font-color-active;
    }
  }
}
