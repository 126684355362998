body {
  font-family: $body__font-family;
  font-weight: $body__font-weight;
  font-style: $body__font-style;
  font-size: $body__font-size;
  line-height: $body__line-height;
  background-color: $body__background-color;
  color: $body__font-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading__font-family;
  font-weight: $heading__font-weight;
  font-style: $heading__font-style;
  text-rendering: $heading__text-rendering;
  color: $heading__font-color;
}

h1,
.h1,
h2,
.h2 {
  font-family: $font-family--sans;
}

h1,
.h1 {
  font-size: rem(24);
  line-height: 1.42;
  margin: rem(45) 0 rem(30);

  @include mq(large) {
    font-size: rem(40);
    line-height: 1.2;
    margin: rem(130) 0 rem(43);
  }
}

h2,
.h2 {
  font-size: rem(20);
  line-height: 1.2;
  margin: rem(57) 0 rem(42);

  @include mq(large) {
    font-size: rem(30);
    margin: rem(106) 0 rem(86);
  }
}

h3,
.h3 {
  font-size: rem(16);
  line-height: 1.25;
  margin: rem(42) 0 rem(16);

  @include mq(large) {
    font-size: rem(24);
  }
}

h4,
.h4 {
  font-size: rem(20);
  line-height: 1.2;
  margin: rem(38) 0 rem(40);

  @include mq(large) {
    font-size: rem(30);
    margin: rem(90) 0 rem(48);
  }
}

h5,
h6,
.h5,
.h6 {
  font-size: rem(16);
  line-height: 1.25;
  margin: rem(15) 0 rem(24);

  @include mq(large) {
    font-size: rem(24);
    margin: rem(51) 0 rem(17);
  }
}

p {
  font-family: $paragraph__font-family;
  font-weight: $paragraph__font-weight;
  font-size: $paragraph__font-size;
  line-height: $paragraph__line-height;
  margin-top: 0;
  margin-bottom: $paragraph__margin-bottom;
  text-rendering: $paragraph__text-rendering;

  @include mq(medium) {
    font-size: rem(16);
  }

  @include mq(large) {
    font-size: rem(20);
    line-height: 1.4;
  }
}

a {
  text-decoration: $anchor__text-decoration;
  color: $anchor__font-color;

  p & {
    text-decoration: underline;
  }

  &:hover {
    color: $anchor__font-color-hover;
  }

  &:active {
    color: $anchor__font-color-active;
  }
}

ul,
ol {
  list-style-position: $list__style-position;
  margin-left: $list__side-margin;
  padding-left: $list__side-padding;
  font-size: $list__font-size;

  @include mq(medium) {
    font-size: rem(16);
  }

  @include mq(large) {
    font-size: rem(20);
    line-height: 1.4;
  }
}

ol {
  padding-left: $list__ol-side-padding;
}

ul ul,
ol ol {
  margin-left: $list__nested-margin;
}

hr {
  border-width: $hr__border-width;
  border-style: $hr__border-style;
  border-color: $hr__border-color;
  margin-top: $hr__margin-top;
  margin-bottom: $hr__margin-bottom;

  @include mq(large) {
    margin-top: rem(24);
    margin-bottom: rem(34);
  }
}

small,
.small {
  color: $color__accent--dark;
  font-size: rem(12);
  font-weight: $font-weight--light;
  line-height: 1.5;
  margin-bottom: rem(42);

  @include mq(large) {
    font-size: rem(16);
    line-height: 1.5;
    margin: rem(32) 0 rem(60);
  }
}

blockquote {
  font-family: $blockquote__font-family;
  font-size: $blockquote__font-size;
  font-weight: $blockquote__font-weight;
  font-style: $blockquote__font-style;
  line-height: 1.5;
  margin: $blockquote__margin;

  @include mq(large) {
    font-size: rem(40);
    line-height: 1.4;
  }
}
