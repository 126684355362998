html,
body {
  height: 100%;
}

.bg--accent {
  background-color: $color__accent;
}

.bg--accent-light {
  background-color: $color__accent--light;
}

article {
  @include span-columns(12);
  padding: 0 rem(12);
}

// LARGE
// ----------------------------------------
@include mq(large) {
  article {
    padding: 0 rem(24);
  }
}
