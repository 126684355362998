.newsletter-form {
  fieldset {
    margin-top: rem(50);
    margin-bottom: 0;
  }

  legend {
    color: $color__accent--medium;
  }

  button {
    margin-bottom: 0;
  }

}

.indicates-required {
  text-align: right;
  margin-top: 10px;
}

#mc_embed_signup { // stylelint-disable-line selector-max-specificity
  width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
}
