// stylelint-disable font-family-name-quotes
@mixin hide-text {
  font: 0 / 0 a;
  text-shadow: none;
  color: transparent;
}
// stylelint-enable font-family-name-quotes

// Mixin to reset the neat omega mixin
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: +flex-gutter();
  }

  &:nth-child(#{$nth}+1) {
    clear: none;
  }
}

// Mixin for Media Queries
@mixin mq($mq__breakpoint, $mq__direction: 'min-width', $mq__breakpoints: $breakpoints, $mq__media: '') {
  $mq__query: '';

  @if $mq__breakpoint == landscape or $mq__breakpoint == portrait {
    $mq__query: '#{$mq__media} (orientation: #{$mq__breakpoint})';
  }

  @else {
    // If $mq__breakpoint is a key that exists in
    // $mq__breakpoints, get and use the value
    @if map-has-key($mq__breakpoints, $mq__breakpoint) {
      $mq__breakpoint: map-get($mq__breakpoints, $mq__breakpoint);
    }
    $mq__query: '#{$mq__media} (#{$mq__direction}: #{$mq__breakpoint})';
  }

  @media #{$mq__query} {
    @content;
  }
}

// Mixin for breaking long words
@mixin word-break() {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

// Mixin for title lines
@mixin headline-line($width: rem(30), $margintop: rem(10), $marginbottom: rem(10)) {
  content: '';
  display: block;
  margin: $margintop auto $marginbottom;
  height: 2px;
  width: $width;
  background-color: $color__primary;
}
