.two-column {
  @include outer-container;
}

// LARGE
// ----------------------------------------
@include mq(medium) {
  .two-column__first,
  .two-column__second {
    @include span-columns(5);
  }

  .two-column__first {
    @include shift(1);
  }
}
