.footer-nav__group-title,
.footer-nav__list {
  display: none;
}

@include mq(medium) {
  .footer-nav {
    margin: rem(60) 0 rem(20);
    border-bottom: 1px solid $hr__border-color;
  }

  .footer-nav__inner {
    max-width: rem(1440);
    margin: 0 auto;
    padding: 0 space(1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-nav__wrapper {
    flex: 1 0 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-left: 1px solid $hr__border-color;
  }

  .footer-nav__group {
    flex: 1 1 33%;
    width: 33%;
    color: $color__accent--medium;
    padding: rem(10) rem(10) rem(30);
    font-size: rem(18);
    border-right: 1px solid $hr__border-color;

    &--with-form {
      display: flex;
      flex-direction: column;

      form {
        margin-top: auto;
      }
    }
  }

  .footer-nav__group-title {
    display: block;
    margin: 0 0 rem(5) 0;
    font-size: 1em;
    color: inherit;
    font-weight: $font-weight--bold;
  }

  .footer-nav__list {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-weight: $font-weight--medium;
    font-size: inherit;

    a {
      @include word-break();
      display: inline-block;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding: rem(4) 0;

      &:hover {
        color: $color__primary;
      }

      &:active {
        color: $color__dark;
      }
    }
  }
}

@include mq(xlarge) {
  .footer-nav__inner {
    flex-wrap: nowrap;
  }

  .footer-nav__wrapper {
    flex: 1 1 50%;
    width: 50%;
  }

  // stylelint-disable selector-max-specificity
  .footer-nav__wrapper {
    &:first-child {
      .footer-nav__group {
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  // stylelint-enable selector-max-specificity
}
