.teaser-list {
  @include outer-container;
  padding: space(1) space(1) space(2);
}

// MEDIUM
@include mq(medium) {
  .teaser-list__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .teaser-list {
    .teaser {
      @include span-columns(4, no-display);
      @include omega(3n);
      flex-grow: 0;
    }
  }
}

// LARGE
@include mq(large) {
  .teaser-list {
    padding: 0 space(1);
  }
}
