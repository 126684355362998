$header__max-width: 1600;

.header {
  border-top: rem(5) solid $color__primary;
  min-height: rem(64);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    outline-color: $color__dark;
  }
}

.header__content {
  @include outer-container();
  width: 100%;
  padding: rem(5) space(1) 0;
  display: flex;
}

.header__logo {
  order: 1;
  display: block;
  margin-right: auto;
  color: $color__dark;

  &,
  .icon {
    width: rem(151);
    height: rem(63);
  }
}

.header__nav {
  order: 2;
}

//STATES
.topbar--open {
  .header__logo {
    z-index: 101;
  }
}

//VARIANTS
.header--with-img {
  height: rem(150);
  background-size: cover;
  background-position: center center;
  background-blend-mode: multiply;
  background-color: $color__accent--medium;

  .header__logo {
    color: $color__bright;
  }
}

@include mq(medium) {
  .header {
    min-height: rem(100);
  }
}

@include mq(large) {
  .header {
    position: relative;
    height: rem(500);
  }

  .header__content {
    display: block;
  }

  .header__logo {
    order: 0;
    margin-top: rem(8);
    margin-left: 0;

    &,
    .icon {
      width: rem(203);
      height: rem(87);
    }

    .topbar--open & {
      color: $color__dark;
    }

    .header--with-img & {
      color: $color__bright;
    }
  }

  .header__nav {
    display: flex;
  }
}

@include mq(xlarge) {
  .header__logo {
    margin-top: 0;

    &,
    .icon {
      width: rem(232);
      height: rem(94);
    }
  }
}
