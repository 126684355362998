.teaser {
  background-color: $color__accent--light;
  margin: 0 0 space(1);
  padding: rem(42) rem(12);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.teaser__title {
  width: 100%;
  margin: 0;
  text-align: center;

  &::after {
    @include headline-line();
  }
}

.teaser__content {
  width: 100%;
  margin: 0 0 rem(12);
  text-align: center;
}

.teaser__btn {
  margin-top: auto;
  margin-bottom: 0;
}

// MEDIUM
@include mq(medium) {
  .teaser {
    display: flex;
    flex: 1 1 auto;
  }
}

// LARGE
@include mq(large) {
  .teaser {
    margin-bottom: space(2);
    padding: rem(40) rem(12) rem(42);
  }

  .teaser__content {
    margin-bottom: rem(24);
  }

  .teaser__title {
    &::after {
      width: rem(40);
    }
  }
}
