.tile-list {
  @include outer-container(rem(1600));
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .tile {
    width: 100%;
    border-bottom: 1px solid $color__bright;
  }
}

@include mq(medium) {
  .tile-list {
    .tile {
      width: 50%;

      + .tile {
        border-left: 1px solid $color__bright;
      }
    }

    .tile--large {
      width: 100%;
    }

    .tile + .tile--large,
    .tile--large + .tile {
      border-left: 0;
    }
  }
}
