.team-list {
  @include outer-container();
  padding: 0 space(1);
}

.team-list__inner {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@include mq(medium) {
  .team-list__item {
    @include span-columns(6, no-display);
    @include omega(2n);
  }
}

@include mq(large) {
  .team-list__item {
    @include omega-reset(2n);
    @include span-columns(4, no-display);
    @include omega(3n);
  }
}
